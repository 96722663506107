import styled from "styled-components"

import { MediaQuery } from "../../../assets/css/media"

export const ImageCardWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 60px;
  margin-right: auto;
  margin-left: auto;
  ${MediaQuery.md`
    width: 90%;
    margin-top: 48px;
    margin-bottom: 40px;
  `}
`

export const ImageBlockWrapper = styled.div`
  width: 50%;
  overflow: visible;
`

export const ImageWrapper = styled.img`
  max-width: 140%;
  margin-right: 40%;
  overflow: visible;
  float: left;
  ${({ isAltCardStyle }) =>
    isAltCardStyle &&
    `
    float: right;
    margin-left: 6%;
    margin-right: 0;
  `}
  ${MediaQuery.xs`
    max-width 190%;
    margin-right: 90%;
    ${({ isAltCardStyle }) =>
      isAltCardStyle &&
      `
      margin-left: -6%;
      margin-right: 0;
    `}
  `}
`

export const CardWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  width: 50%;
  z-index: 2;
  pointer-events: none;
`

export const CardContentContainer = styled.div`
  width: 106%;
  margin-top: 8%;
  pointer-events: auto;
  background-color: #f7f7f7;
  padding: 8%;
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  margin-left: -6%;
  float: right;
  align-items: flex-start;
  cursor: default;
  ${({ isAltCardStyle }) =>
    isAltCardStyle &&
    `
    float: left;
    margin-left: 0;
    margin-right: 0;
    width: 180%;
  `}
  ${MediaQuery.xs`
    margin-top: calc(98%);
    margin-left: -80%;
    width: 180%;
    ${({ isAltCardStyle }) =>
      isAltCardStyle &&
      `
      margin-left: 0;
      margin-right: -80%; 
      width: 500%;
    `}
  `}
`

export const CardTitleWrapper = styled.div`
  font-size: 2.25rem;
  text-align: left !important;
  pointer-events: none;
`

export const CardDescriptionWrapper = styled.div`
  letter-spacing: 0.03em;
  width: 100%;
  font-size: 1em;
  text-align: left !important;
  pointer-events: none;
`

export const CardButtonWrapper = styled.button`
  margin-top: 6%;
  text-align: left !important;
  pointer-events: auto;
  font-size: 1em;
  line-height: 1em;
  padding: 1em 2.5em;
  border: 2px solid #1f4b54;
  background-color: rgba(255, 255, 255, 0);
  color: #1f4b54;
  cursor: pointer;
`
