import styled from "styled-components"
// import { themeGet } from "@styled-system/theme-get"
// backgroundColor: ${themeGet("fontSize.text")};

export const PanelWrapper = styled.div`
  background-color: #0071ac;
  padding: 60px 0 60px 0;
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
`

export const PanelTextWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  text-align: center;
`

export const TextWrapper = styled.div`
  color: #fff;
  h1 {
    color: #fff;
  }
`
