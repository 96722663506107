import React, { Fragment } from "react"
import { Container, Row, Col } from "reactstrap"
import { MainWrapper } from "./services-area.stc"
import servicesQuery from "./query-services"
import practicesQuery from "./query-practices"
import ImageCard from "../../../components/cards/imageCard"
import TextPanel from "../../../components/panels/text-panel"

const ServicesArea = () => {
  const servicesServices = servicesQuery()
  const practicesServices = practicesQuery()
  return (
    <MainWrapper>
      <Container>
        <Row>
          <Col lg={12}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a name="whatwedo" className="anchor">
              <Fragment>
                <Row>
                  <Col key="services" md={12}>
                    <TextPanel
                      title="Services"
                      description="We help companies and potential investors understand what users need, prioritize what to build and rally everyone around your roadmap, business model and investment objectives."
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: "46px", marginTop: "18px" }}>
                  {servicesServices.map(service => (
                    <Col
                      key={service.fields.slug}
                      md={12}
                      style={{ marginBottom: "32px" }}
                    >
                      <ImageCard
                        imagePath={
                          service.frontmatter.format[0].image.childImageSharp
                            .sizes.src
                        }
                        cardTitle={service.frontmatter.title}
                        cardDescription={service.frontmatter.description}
                        isAltCardStyle={
                          parseInt(service.frontmatter.sortOrder) % 2 === 0
                        }
                        readMore={service.frontmatter.readMore}
                        moreInfoLabel="Read more"
                        moreInfoLink={service.fields.slug}
                      />
                    </Col>
                  ))}
                </Row>
                <Row style={{ marginBottom: "32px" }}>
                  <Col key="practices" md={12}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a name="practices" className="anchor">
                      <TextPanel
                        title="Practices"
                        description="We are a product, solutions, and business innovation agency helping companies poised for growth to build great things and exceed their objectives, faster."
                      />
                    </a>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "32px" }}>
                  {practicesServices.map(service => (
                    <Col
                      key={service.fields.slug}
                      md={12}
                      style={{ marginBottom: "32px" }}
                    >
                      <ImageCard
                        imagePath={
                          service.frontmatter.format[0].image.childImageSharp
                            .sizes.src
                        }
                        cardTitle={service.frontmatter.title}
                        cardDescription={service.frontmatter.description}
                        isAltCardStyle={
                          parseInt(service.frontmatter.sortOrder) % 2 === 0
                        }
                        readMore={service.frontmatter.readMore}
                        moreInfoLabel="Read more"
                        moreInfoLink={service.fields.slug}
                      />
                    </Col>
                  ))}
                </Row>
              </Fragment>
            </a>
          </Col>
        </Row>
      </Container>
    </MainWrapper>
  )
}

export default ServicesArea
