import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const servicesServicesData = useStaticQuery(graphql`
    query ServicesServicesQuery {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            slugPath: { eq: "/services/" }
            category: { eq: "services" }
          }
        }
        sort: { fields: frontmatter___sortOrder, order: ASC }
      ) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
            description
            sortOrder
            category
            readMore
            format {
              image {
                childImageSharp {
                  sizes(quality: 90) {
                    src
                  }
                }
              }
            }
          }
          excerpt
        }
      }
    }
  `)
  const servicesServices = servicesServicesData.allMarkdownRemark.nodes
  return servicesServices
}
