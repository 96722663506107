import React from "react"
import SEO from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header"
import Footer from "../containers/layout/footer"
import HeroArea from "../containers/page-areas/hero-area"
import ServicesArea from "../containers/page-areas/services-area"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Anchor from "../components/shared/anchor"
import { themeGet } from "@styled-system/theme-get"
import { MediaQuery } from "../assets/css/media"

const IndexPage = () => {
  const heroData = useStaticQuery(graphql`
    query HomeHeroQuery {
      homeJson(id: { eq: "home-hero-content" }) {
        image {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 750, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  const { image } = heroData.homeJson
  return (
    <Layout>
      <SEO title="Envy Forge" />
      <Header />
      <div className="main-content">
        <HeroArea
          image={image}
          marqueetext="Get the right products to market and grow, faster."
          title="We help companies and potential investors understand what users need, prioritize what to build and rally everyone around your roadmap, business model and investment objectives."
          links={servicesLinks()}
        />
        <ServicesArea />
      </div>
      <Footer />
    </Layout>
  )
}

export default IndexPage

const servicesLinks = () => {
  return (
    <div style={{ display: "inline", whiteSpace: "nowrap" }}>
      <LinksLabel>What we do:</LinksLabel>
      <ServiceLink path="/#whatwedo">Services</ServiceLink>
      <LinksLabel>|</LinksLabel>
      <ServiceLink path="/#practices">Practices</ServiceLink>
    </div>
  )
}

const LinksLabel = styled.div`
  color: ${themeGet("colors.headingColor")};
  font-family: "Montserrat";
  font-size: 1.5em;
  font-weight: 500;
  padding-bottom: 10px;
  white-space: nowrap;
  display: inline;
  margin: 0 0 0 14px;
  ${MediaQuery.sm`
      text-shadow: 3px 8px 8px rgba(255, 255, 255, 0.4), 5px 8px 10px rgba(255, 255, 255, 0.4);
    `}
`

const ServiceLink = styled(Anchor)`
  color: ${themeGet("colors.themeColor")};
  font-family: "Montserrat";
  font-size: 1.5em;
  font-weight: 500;
  padding-bottom: 14px;
  white-space: nowrap;
  margin: 0 0 0 10px;
  ${MediaQuery.sm`
      text-shadow: 3px 8px 8px rgba(255, 255, 255, 0.4), 5px 8px 10px rgba(255, 255, 255, 0.4);
    `}
`
