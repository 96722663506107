import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import {
  ImageCardWrapper,
  ImageBlockWrapper,
  ImageWrapper,
  CardWrapper,
  CardContentContainer,
  CardTitleWrapper,
  CardDescriptionWrapper,
  CardButtonWrapper,
} from "./imageCard.stc"

function ImageCard(props) {
  return (
    <ImageCardWrapper>
      {!props.isAltCardStyle && (
        <ImageBlockWrapper>
          <ImageWrapper
            src={props.imagePath}
            isAltCardStyle={props.isAltCardStyle}
          />
        </ImageBlockWrapper>
      )}
      <CardWrapper>
        <CardContentContainer isAltCardStyle={props.isAltCardStyle}>
          <CardTitleWrapper>
            <p>{props.cardTitle}</p>
          </CardTitleWrapper>
          <CardDescriptionWrapper>
            <p>{props.cardDescription}</p>
          </CardDescriptionWrapper>
          {props.readMore && props.moreInfoLabel && props.moreInfoLink && (
            <CardButtonWrapper>
              <Link to={props.moreInfoLink}>{props.moreInfoLabel}</Link>
            </CardButtonWrapper>
          )}
        </CardContentContainer>
      </CardWrapper>
      {props.isAltCardStyle && (
        <ImageBlockWrapper isAltCardStyle>
          <ImageWrapper
            src={props.imagePath}
            isAltCardStyle={props.isAltCardStyle}
          />
        </ImageBlockWrapper>
      )}
    </ImageCardWrapper>
  )
}

ImageCard.propTypes = {
  imagePath: PropTypes.string.isRequired,
  cardTitle: PropTypes.string.isRequired,
  cardDescription: PropTypes.string.isRequired,
  isAltCardStyle: PropTypes.bool,
  moreInfoLabel: PropTypes.string,
  moreInfoLink: PropTypes.string,
}

ImageCard.defaultProps = {
  isAltCardStyle: false,
  moreInfoLabel: "",
  moreInfoLink: "",
}

export default ImageCard
