import React from "react"
import { PanelWrapper, PanelTextWrapper, TextWrapper } from "./text-panel.stc"
import PropTypes from "prop-types"

const TextPanel = ({ title, description }) => {
  return (
    <PanelWrapper>
      <PanelTextWrapper>
        <TextWrapper>
          <h1>{title}</h1>
        </TextWrapper>
        <TextWrapper>
          <span style={{ color: "#fff" }}>{description}</span>
        </TextWrapper>
      </PanelTextWrapper>
    </PanelWrapper>
  )
}

TextPanel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default TextPanel
